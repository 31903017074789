.App {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url(./wenshimmer.jpg);
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
  left: 0;
}

.App-header:after {
  -webkit-backdrop-filter: blur(5px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
  backdrop-filter: blur(5px); /* Supported in Chrome 76 */

  content: "";
  display: block;
  position: absolute;
  width: 100%; height: 100%;
  top: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ContainerStyled {
  margin: auto;
  padding: 1rem;
  position: relative;
}

.DateStyled {
  margin-bottom: 2rem;
}

.DateStyled.h1 {
  color: var(--color-heading);
  font-family: var(--font-family-heading);
  font-size: clamp(4rem, 2vw, 99rem);
  font-weight: 300;
  letter-spacing: 0.1875em;
  margin: unset;
  text-align: center;
  text-transform: uppercase;
}


.WrapperStyled {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  min-width: 90vw;

}

.CounterStyled {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  color: var(--color-counter);
  display: flex;
  flex-direction: column;
  font-family: "JetBrains Mono", mono;
  font-size: clamp(1rem, 8vw, 99rem);
  font-weight: 100;
  line-height: 1;
  padding: 2vw;
  text-align: center;


}

.CounterStyled h2 {
  color: var(--color-heading);
    font-family: var(--font-family-heading);
    font-size: clamp(1rem, 2vw, 99rem);
    font-weight: 300;
    letter-spacing: .1875em;
    margin: 1.25rem 0 0;
    order: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;

}

.Socialbox {
border: 1px solid black;
padding: 0 3em;
background: rgba(255, 255, 255, 0.7);

border-radius: 1rem;
color: var(--color-counter);
font-family: "JetBrains Mono", mono;

}
.SocialboxWrapper {
  padding: 0.5em 2em;
}

.Socialbar {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 2rem;
 
  cursor:pointer;
  flex-wrap: wrap;
}

.Socialbar h2 {
  color: var(--color-heading);
  font-family: var(--font-family-heading);
  font-size: clamp(1rem, 2vw, 99rem);

}

.info {
  position: absolute;
  bottom: 5px;
  right: 10px;
  z-index: 20;
}

.info a {
  color: white;
}

@media (min-width: 48em) {
  .ContainerStyled {
    padding: 4rem;
  }

  .Socialbar {
    display: flex;
    margin-top: -2.4rem;
  }
}

@media (min-width: 35.5em) {
  .WrapperStyled {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0 2rem;
    min-width: 0;
  }
 
}